import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, switchMap, tap, throwError } from 'rxjs';
import { API_URL } from 'src/app/constants/API/api-url';
import { environment } from 'src/environments/environment.development';
import { GstVerificationService } from './httpcalls/gst-verification.service';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { UUIDGenerator } from '../utils/UUIDGenerator';
import { LoginServiceService } from './httpcalls/login-service.service';
import { ITokenValidityRequest } from '../models/authentication/jwt/jwt.model';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    constructor(private router: Router, private gstVerificationService: GstVerificationService, private loginService: LoginServiceService, private toastService: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {
        this.requests.push(request);
        let authToken = localStorage.getItem('jwtToken');
        let authTokenApi4Business = localStorage.getItem('api4BusinessToken');
        //for token validity API
        if (
            request.url.includes("auth/validateV2") ||
            request.url.includes("auth/verifymail") ||
            request.url.includes("api/enableSupplierToBuyerHandshake") ||
            request.url.includes('auth/setpwd') ||
            request.url.includes("auth/authenticate/2fa") ||
            request.url.includes('auth/forgotpwdmailV2') ||
            request.url.includes('user/checkverificationcode') ||
            request.url.includes('user/acceptinviteV2') ||
            request.url.includes('api/checkUserExists')
        ) {
            return next.handle(request);
        }
        if (authToken) {
            const requestForTokenValidity: ITokenValidityRequest = {
                jwttoken: localStorage.getItem("jwtToken") || "",
                productId: ENV_VARIABLES.PRODUCT_ID
            };

            // Call the service to validate token
            return this.loginService.checkTokenValidity(requestForTokenValidity).pipe(
                tap((response: any) => {
                    //no nned to replace token if 200 then ok
                }),
                switchMap(() => {
                    //start of logic
                    if (request.url.includes("api4business") && !request.url.includes(API_URL.verifyGstin.generateOAuthTokenForApi4Business)) {
                        if (authTokenApi4Business == null) {
                            this.gstVerificationService.generateOauthTokenForApi4Business().subscribe((resp) => {
                                authTokenApi4Business = localStorage.getItem('api4BusinessToken');
                            });
                        }
                        request = request.clone({
                            setHeaders: {
                                'Authorization': 'Bearer ' + authTokenApi4Business,
                            }
                        });
                        return next.handle(request);
                    }
                    if ((request.url.includes(environment.CAM_URI)) || !(request.url.includes(environment.ENVIRONMENT) || request.url.includes("localhost") || request.url.includes(environment.APIGEE_MANAGEMENT_SERVICE_BASE_URL) || (request.url.includes(environment.HANDSHAKE_URL))  )) {
                        return next.handle(request);
                    }

                    // For Api4Business request's we need one more token (Calling api4Business API's through supplier middleware)
                    if (request.url.includes("api4BusinessSupplier")) {
                        if (authTokenApi4Business == null) {
                            this.gstVerificationService.generateOauthTokenForApi4Business().subscribe((resp) => {
                                authTokenApi4Business = localStorage.getItem('api4BusinessToken');
                            });
                        }
                        request = request.clone({
                            setHeaders: {
                                'Authorization': 'Bearer ' + authToken,
                                'api4BusinessToken': '' + authTokenApi4Business
                            }
                        });
                        request = this.addUUID(request);
                        return next.handle(request).pipe(
                            catchError((error: HttpErrorResponse) => {
                                if (error.status === 401) {
                                    localStorage.removeItem('jwtToken');
                                    this.router.navigateByUrl("/");
                                }
                                return throwError(error);
                            })
                        )
                    }
                    else {
                        request = this.addUUID(request);
                        console.log("In Normal services API token interceptor")
                        request = request.clone({
                            setHeaders: {
                                'Authorization': 'Bearer ' + authToken,
                            }
                        });
                        return next.handle(request).pipe(
                            catchError((error: HttpErrorResponse) => {
                                if (error.status === 401) {
                                    localStorage.removeItem('jwtToken')
                                    this.router.navigateByUrl("/");
                                }
                                return throwError(error);
                            })
                        )
                    }

                    //end of switch logic blk
                }),
                catchError(error => {
                    // Handle any errors from token validation
                    if (error.url.includes("auth/validateV2")) {
                        if (error?.status == '500' && localStorage.length > 0) {
                            localStorage.clear();
                            console.error('Token expired', error);
                            this.toastService.error("Your session has expired. Please log in again.", "", {
                                timeOut: 7000, // Duration in milliseconds after which the toast will disappear
                                closeButton: true, // Optional: Shows a close button on the toast
                                progressBar: true, // Optional: Shows a progress bar
                            });
                        }
                        else if (localStorage.length > 0) {
                            localStorage.clear();
                            console.error('Error in token validation or request forwarding', error);
                            this.toastService.error("User logging off due to another login.", "", {
                                timeOut: 7000, // Duration in milliseconds after which the toast will disappear
                                closeButton: true, // Optional: Shows a close button on the toast
                                progressBar: true, // Optional: Shows a progress bar
                            });
                        }
                        this.router.navigateByUrl("/");
                    }

                    console.error('Error in token validation or request forwarding', error);
                    //this.router.navigateByUrl("/");
                    return throwError(() => error);

                })
            );
        } else {
            request = this.addUUID(request);
            console.log("In Normal services API token interceptor")
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        localStorage.removeItem('jwtToken')
                        this.router.navigateByUrl("/");
                    }
                    return throwError(error);
                })
            )
        }
    }
    getProgress(): number {
        return this.requests.length > 0 ? (this.requests.length / this.requests.length) * 100 : 0;
    }

    addUUID(request: HttpRequest<any>): HttpRequest<any> {
        if(!request.headers.has("uuid") && !request.url.includes(environment.MDM_API_URL) && !request.url.includes(environment.CAM_API_URL)) {
            request = request.clone({
                setHeaders: {
                    'uuid': UUIDGenerator.generateUUID()
                }
            })
        }
        return request;
    }
}